import React from 'react';
import { StaticImage } from "gatsby-plugin-image";

import Seo from '../components/Seo';
import Layout from '../components/Layout';


const Trabajos = () => {

  return (
    <Layout>
      <Seo title={"Digital System DA - Trabajos Realizados"} />
      <div className="bg-secondary pb-20 md:pb-0">
          <section className='md:h-screen pt-20 md:pt-0 md:flex md:items-center'>
                <div className="w-11/12 mx-auto md:flex md:items-center md:justify-between">
                  <div className="mb-20 w-52 mx-auto h-52 md:w-96 md:h-96 relative bg-[#19284C]">
                    {/*onMouseOver={() => handleOverMouse()} onMouseLeave={() => handleMouseLeave()} */}
                      <a className='pointer absolute top-10 left-10 flex items-center justify-center w-full h-full bg-black bg-transparent backdrop-brightness-75' href="https://www.lafirmaconsultores.com.ar/" target="_blank" rel="noopener noreferrer">
                            <div className='w-4/5 '>
                                <StaticImage
                                    src="../images/logo_lafirma.webp"
                                    alt="La Firma Asociados"
                                    className='mx-auto w-fit block'
                                    placeholder="blurred"
                                    quality={100}
                                />
                            </div>
                          {/* <img className="w-4/5" src={logo_lafirma} alt="La Firma Asociados" /> */}
                          <div className="absolute m-auto text-xl text-secondary bg-third h-3/5 md:h-2/5 opacity-0 transition-all w-3/5 md:w-2/5 flex items-center justify-center rounded-full hover:scale-100 hover:opacity-100">
                              <p className='text-sm md:text-base'>Visitar Sitio</p>
                          </div>
      
                      </a>
                          
                          
                  </div>
                  <div className='md:w-7/12'>
                      <div className="w-11/12 mx-auto">

                          <p className="text-3xl md:text-5xl font-bold text-primary">La Firma Consultores</p>
                          <p className="mt-10 md:mt-20 text-third text-lg md:text-xl">Equipo de profesionales especializados en diferentes sectores.
                              Procuradores, abogados, administradores de empresas, contadores, etc.
                          </p>
                      </div>
                  </div>
              </div>
          </section>
      </div>
    </Layout>
  )
}

export default Trabajos